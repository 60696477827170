import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import Layout, { ScheduleButton } from "../components/layout";

import * as styles from "./index.module.css";
import content from "./index.content.yml";

export default function IndexPage({ data }) {
  const imgsByPath = data?.images?.edges?.reduce(
    (imgMap, { node }) => ({
      ...imgMap,
      [`/src/${node.relativePath}`]: node,
    }),
    {}
  );

  return (
    <Layout>
      <main>
        <section id="about" className={styles.about}>
          <GatsbyImage
            alt="about"
            className={styles.leftFloatingImgWrapper}
            image={getImage(imgsByPath[content.about.image])}
          />
          <ReactMarkdown>{content.about.copy}</ReactMarkdown>
        </section>
        <section id="training">
          <GatsbyImage
            alt="training"
            className={styles.rightFloatingImgWrapper}
            image={getImage(imgsByPath[content.training.image])}
          />
          <ReactMarkdown>{content.training.copy}</ReactMarkdown>
        </section>
        <section id="join" className={styles.join}>
          <StaticImage
            alt="logo"
            className={styles.leftFloatingImgWrapper}
            src="../images/about.jpg"
            style={{ maxWidth: "650px" }}
          />
          <h2>Join the Erin Go Dog Pack</h2>
          <p>I look forward to welcoming you and your dog into the pack!</p>
          <ScheduleButton
            calendly={data.site.siteMetadata.calendly}
            social={data.site.siteMetadata.social}
          />
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query IndexQuery {
    images: allFile(filter: { relativeDirectory: { eq: "images" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    site {
      siteMetadata {
        calendly
        social
      }
    }
  }
`;
